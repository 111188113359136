<template>
    <div>
        
        <div>
            <vue-drawing-canvas 
                :width='400'
                :height="242"
                :image.sync="image"
                :stroke-type="strokeType"
                :line-cap="lineCap"
                :line-join="lineJoin"
                :fill-shape="fillShape"
                :eraser="eraser"
                :lineWidth="line"
                :color="color"
                :background-color="backgroundColor"
                :background-image="`${backgroundImage}`"
                :watermark="watermark"
                :initial-image="initialImage"
                saveAs="png"
                :lock="disabled"
                    @mousemove.native="getCoordinate($event)"
                    :additional-images="additionalImages"
                :styles="{
                    background: 'url(../img/car-background.png)',
                    border: '1px solid #000'
                }"
                ref="VueCanvasDrawing" />
        </div>
        
    </div>
</template>

<script>
  import VueDrawingCanvas from "vue-drawing-canvas";

  export default {
    name: "MyComponent",
    components: {
      VueDrawingCanvas,
    },
    props: {
        CarsList: Array,
        carFirst: String
    },
    data() {
        return {
            selectedCar: this.carFirst,
            initialImage: [
                {
                type: "dash",
                from: {
                    x: 262,
                    y: 154,
                },
                coordinates: [],
                    color: "#000000",
                    width: 1,
                    fill: false,
                },
            ],
            x: 0,
            y: 0,
            image: "",
            eraser: false,
            disabled: false,
            fillShape: false,
            line: 5,
            color: "#000000",
            strokeType: "dash",
            lineCap: "square",
            lineJoin: "miter",
            backgroundColor: "#FFFFFF",
            backgroundImage: '../../img/car-background.png',
            watermark: null,
            additionalImages: [],
        }
    },
    mounted() {
        if ("vue-drawing-canvas" in window.localStorage) {
            this.initialImage = JSON.parse(
                window.localStorage.getItem("vue-drawing-canvas")
            );
        }
    },
    created(){
        // this.setImage()
        // setInterval(() => {console.log("image",this.image)},10000)
    },
    methods: {
        save() {
            console.log(this.$parent);
            // this.$parent.images.push({
            //     image: this.image,
            //     carplate: this.selectedCar
            // })
        },
        async setImage() {
            // let URL = window.URL;
            // this.backgroundImage = URL.createObjectURL('../img/car-background.png');
            // await this.$refs.VueCanvasDrawing.redraw();
        },
        async setWatermarkImage(event) {
            let URL = window.URL;
            this.watermark = {
                type: "Image",
                source: URL.createObjectURL(event.target.files[0]),
                x: 0,
                y: 0,
                imageStyle: {
                width: 600,
                height: 400,
                },
            };
            await this.$refs.VueCanvasDrawing.redraw();
        },
        getCoordinate(event) {
            let coordinates = this.$refs.VueCanvasDrawing.getCoordinates(event);
            this.x = coordinates.x;
            this.y = coordinates.y;
            },
            getStrokes() {
            window.localStorage.setItem(
                "vue-drawing-canvas",
                JSON.stringify(this.$refs.VueCanvasDrawing.getAllStrokes())
            );
            alert(
                "Strokes saved, reload your browser to see the canvas with previously saved image"
            );
        },
        removeSavedStrokes() {
            window.localStorage.removeItem("vue-drawing-canvas");
            alert("Strokes cleared from local storage");
        },
    },
  };
</script>